import JSEncrypt from 'jsencrypt';
import { Base64 } from 'js-base64';


(() => {
  'use strict';

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  const forms = document.querySelectorAll('.needs-validation');
  // Loop over them and prevent submission
  Array.prototype.slice.call(forms).forEach((form) => {
    form.addEventListener('submit', (event) => {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  });
})();
var instance = $(".hs__wrapper");
		$.each( instance, function(key, value) {	
			var arrows = $(instance[key]).find(".arrow"),
			prevArrow = arrows.filter('.arrow-prev'),
			nextArrow = arrows.filter('.arrow-next'),
			box = $(instance[key]).find(".hs"), 
			x = 0,
			mx = 0,
			maxScrollWidth = box[0].scrollWidth - (box[0].clientWidth / 2) - (box.width() / 2);
			$(arrows).on('click', function() {
				if ($(this).hasClass("arrow-next")) {
					x = ((box.width() / 2)) + box.scrollLeft() - 10;
					box.animate({
						scrollLeft: x,
					})
				} else {
					x = ((box.width() / 2)) - box.scrollLeft() -10;
					box.animate({
						scrollLeft: -x,
					})
				}
			});
			$(box).on({
				mousemove: function(e) {
					var mx2 = e.pageX - this.offsetLeft;
					if(mx) this.scrollLeft = this.sx + mx - mx2;
				},
				mousedown: function(e) {
					this.sx = this.scrollLeft;
					mx = e.pageX - this.offsetLeft;
				},
					scroll: function() {
					toggleArrows();
				}
			});
			$(document).on("mouseup", function(){
				mx = 0;
			});
			function toggleArrows() {
				if(box.scrollLeft() > maxScrollWidth - 10) {
					// disable next button when right end has reached 
					nextArrow.addClass('disabled');
				} else if(box.scrollLeft() < 10) {
					// disable prev button when left end has reached 
					prevArrow.addClass('disabled')
				} else{
					// both are enabled
					nextArrow.removeClass('disabled');
					prevArrow.removeClass('disabled');
				}
			}
		});

$(document).ready(function() {
	//$('#card-number').hidePassword(true);
	$("input[name='card-number']").inputmask({"mask": "9999 9999 9999 9999"});
	$("input[name='expiry-date']").inputmask({"mask": "99/2099"});	
	//$("input[name='personal-data-phone']").inputmask({"mask": "999 999 999"});
	$('[data-toggle="tooltip"]').tooltip();
	$('.select2.choose-company-profile').select2({
		theme: "material"
	}).on('change', function() {	
		var val = $(this).val();
		if(val == 1) {
			$('#jdgPersonalData').fadeIn();
			$("#jdgPersonalData input.needs-required").prop('required',true);
		}else{
			$('#jdgPersonalData').fadeOut();
			$("#jdgPersonalData input.needs-required").prop('required',false);
		}
    });
	$(document).on("change", 'input[name="representation-statement-checkbox"]', function() {
		var representation_id = $(this).attr('data-id');
       
		if($('input[name="representation-statement-checkbox"]').is(':checked')){
			$('#verificationMetod'+representation_id).show('slow');
			$('#sendVerification'+representation_id).hide();
		}else{
			$('#verificationMetod'+representation_id).hide('slow');
			$('#sendVerification'+representation_id).show();
		}
    });
	$('#packageItems .package-item').on('click', function(e) {
		$('#packageItems .package-item').removeClass('active');
		$(this).addClass('active');
		var id = $(this).attr('data-id');
		$('#packageCarousel .single-item').hide('fast');
		$('#packageCarousel #step-'+id).show('fast');
	});
	$('#verificationMetod .verify-me').click(function() {document.forms[0].submit()});
	
	
	$('.choose-package').on('click', function(){
		calcSummaryPrice(package_data['package_date'], $(this).attr('data-price'), $(this).attr('data-price-period'), 1);
		package_data['package_price'] = $(this).attr('data-price');
		package_data['package_frequency'] = $(this).attr('data-price-period');
    });
	
	$(document).on("change", 'input[name="is-nip"]', function() {
		if($('input[name="is-nip"]').is(':checked')){
			$("input[name='company-data-nip']").prop('required',false);
			$("input[name='company-data-nip']").prop('disabled',true);
			$(".nip-content").css({ opacity: 0.5 });
			
		}else{
			$("input[name='company-data-nip']").prop('required',true);
			$("input[name='company-data-nip']").prop('disabled',false);
			$(".nip-content").css({ opacity: 1 });
		}
	});
	$('#paymentData').submit(function(e) {
			e.preventDefault();
			var cd = $('#card-number').val().replace("/\s/g", "") + '|' + $('#expiry-date').val() + '|' + $('#card-cvc').val() + '|' + document.location.origin;
			
			var encrypt = new JSEncrypt();
			var decoded = Base64.decode(pubkey);
			var encrypted;
			encrypt.setPublicKey(decoded);
			encrypted = encrypt.encrypt(cd);
			
			$("#inp-carddata").val(encrypted);
			$('#inp-card_number').val('');
			$('#inp-expiry_date');
			$('#inp-cvc').val('');
			this.submit();
	});
	$('input[name="company-data-nip"]').blur(function() {
		if( $(this).val().length === 0 ) {
			$(".choose-nip-none").css({ opacity: 1 });
			$("input[name='is-nip']").prop('disabled',false);
		}else{
			$(".choose-nip-none").css({ opacity: 0.5 });
			$("input[name='is-nip']").prop('disabled',true);
		}
	});
	$('#datetimepickerSummaryMain').datetimepicker({
		format:'d-m-Y',
		startDate: new Date(),
		closeOnDateSelect:true,
		timepicker:false,
		weeks:false,
		todayButton:false,
		prevButton:false,
		nextButton:false,
		defaultSelect:false,
		scrollMonth:false,
		changeMonth: true,
		changeYear: true,
		lang:'pl',
		onChangeDateTime:function(dp,$input){
			console.log(package_data); 
			$('#datetimepickerSummaryMain input').val($input.val());
			calcSummaryPrice($input.val(), package_data['package_price'], package_data['package_frequency'], 1);
		}
	});
	
	$('#stepSeven .change-summary-date-alert').on('click', function(){ 
		$('#modal .modal-content').html(modals['resign-contract']);
		$('#modal').modal({backdrop: 'static', keyboard: true});
		$('#modal').modal('show');
	});
	$('#stepSeven #datetimepickerSummaryDate').datetimepicker({
		format:'d-m-Y',
		startDate: new Date(),
		closeOnDateSelect:true,
		timepicker:false,
		weeks:false,
		defaultDate: package_data['package_date'],
		todayButton:false,
		prevButton:false,
		nextButton:false,
		autoClose: true,
		keepOpen: true,
		minView: 2,
		yearOffset: 0,
		defaultSelect:false,
		scrollMonth:false,
		lang:'pl',
		onChangeDateTime:function(dp,$input){
			$('#stepSeven #datetimepickerSummaryDate').html($input.val());
			$('#summarySubmit #retryPayment').hide();
			$('#summarySubmit #goToContract').show();
			$('#representationList button').addClass('sign-document').removeClass('signed');
			$('#representationList button').html(translations['sign_contract']);
			
			calcSummaryPrice($input.val(), package_data['package_price'], package_data['package_frequency'], 1);
		}
	});
	$(document.body).on("click",".change-summary-date",function(){
		$('#modal').modal('toggle');
		$( "#stepSeven #datetimepickerSummaryDate" ).datetimepicker( "show" );
	});
	
		calcSummaryPrice(package_data['package_date'], package_data['package_price'], package_data['package_frequency']);
		$('#stepSeven #datetimepickerSummaryDate').html(package_data['package_date']);
	
		function calcSummaryPrice(startDate, price, frequency_type, update = null) {
			var diffDays = 0;
			price = parseFloat(price);
			var currentMonthInfo = '';
			var date_from = startDate.split("-");
			var f = new Date(date_from[2],date_from[1]-1,date_from[0]);
			if (f.getDate() != 1) {			
				var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
				var nextMonthDate = new Date(f.getFullYear(), f.getMonth()+1, 1);
				diffDays = Math.round(Math.abs((f.getTime() - nextMonthDate.getTime())/(oneDay)));
				console.log(diffDays);
				if (diffDays > 0) {
					currentMonthInfo = " + " +diffDays + " "+ (diffDays == 1 ? "dzień": "dni") + " aktualnego miesiąca";
				}
			}
			var calculatedPrice = price + ((price * 12) / 365) * diffDays;
			var deposit = (price * 12 * 1.23) / 10;
			var frequency = frequency_type ? frequency_type : 'price-yearly';
			if (frequency == 'price-yearly') {
			  calculatedPrice = price * 12 + ((price * 12) / 365) * diffDays;
			}
			if(deposit < package_data['package_deposit']){
				deposit = package_data['package_deposit'];
			}
			if (frequency == 'price-yearly') {
			  $('#currentMonthInfo').html("(12 m-cy" + currentMonthInfo + ")" );
			} else {
			  $('#currentMonthInfo').html("(1 m-c" + currentMonthInfo + ")" );
			}
			var bruttoPrice = calculatedPrice * 1.23;
			var sum = parseFloat(deposit)+parseFloat(bruttoPrice);
			$('#packagePrice').html(price);
			console.log('sum '+sum);
			
			if(package_data['promotion_code_status'] == 1){
				$('#nettoPrice').html((calculatedPrice.toFixed(2)-(calculatedPrice.toFixed(2) * (package_data['promotion_discount'] / 100))).toFixed(2));
				$('#bruttoPrice').html((bruttoPrice.toFixed(2)-(bruttoPrice.toFixed(2) * (package_data['promotion_discount'] / 100))).toFixed(2));
				var promotion_sum = parseFloat(deposit) + parseFloat(bruttoPrice-bruttoPrice * (package_data['promotion_discount'] / 100));
				$('#sumOld').html(parseFloat(sum).toFixed(2));
				var total_sum = parseFloat(promotion_sum).toFixed(2);
				$('#sum').html(total_sum);
				
			}else{
				$('#nettoPrice').html(parseFloat(calculatedPrice).toFixed(2));
				$('#bruttoPrice').html(parseFloat(bruttoPrice).toFixed(2));
				var total_sum = parseFloat(sum).toFixed(2);
				$('#sum').html(parseFloat(total_sum).toFixed(2));
			}
			if(deposit != null || sum != null){
				$('#deposit').html(parseFloat(deposit).toFixed(2));
			}
			if(update != null){
				
				$.ajax({
					type: "POST",
					url: routes['order.updateDataPackage'],
					data: {'date': startDate, 'sum': total_sum, 'deposit': parseFloat(deposit).toFixed(2)}, 
					success: function(data){
						//alert(2);
					}
				});
			}
		}
	$('.select2').select2({
		theme: "material"
	});
	$( ".start-date-value" ).datepicker();
	$('.open-more-text').on('click', function(){ 
		var id = $(this).attr('data-id');
		$('.read-more-clausule[data-id="'+id+'"]').toggle();
	});
	$('.retry-payment').on('click', function(){ 
		$('.addCardForm').toggle();
	});
	$('.toggle-correspondence-address-visibility').on('click', function(e) {
		e.preventDefault();
		var correspondenceAdressContainer = $('.company-data-correspondence-address');
		if (correspondenceAdressContainer.is(':visible')) {
			$(this).html('+ dodaj adres korespondencyjny')
			correspondenceAdressContainer.hide();
			$("#companyCorrespodenceData .needs-required").prop('required',false);
		} else {
			$(this).html('- ukryj adres korespondencyjny')
			correspondenceAdressContainer.show();
			$("#companyCorrespodenceData .needs-required").prop('required',true);
		}
		return false;
	});
	$('.copy-address-holder').on('click', function(e) {
		e.preventDefault();
		var goldplaceAddress = {
		  'company_data_company_street': 'Aleja Jana Pawła II',
		  'company_data_building_number': '27',
		  'company_data_post_code': '00-867',
		  'company_data_city': 'Warszawa'
		}
		for(var key in goldplaceAddress) {	
console.log(key);		
			var replacedKey = key.replace(/\_/g, "-");
			console.log(goldplaceAddress);
			$('div[data-step="dane-firmy"] input[name="'+replacedKey+'"]').val(goldplaceAddress[key]).trigger('blur').addClass('active');
		}
		return false;
	});
});
$(document).ready(function () {


    var navListItems = $('div.setup-panel div a'),
            allWells = $('.setup-content'),
            allNextBtn = $('.nextBtn'),
			allPrevBtn = $('.prevBtn'),
			i = 1;
	allWells.hide();
    navListItems.click(function (e) {
        e.preventDefault();
        var $target = $($(this).attr('href')),
        $item = $(this);
        if (!$item.hasClass('disabled')) {
			navListItems.removeClass('btn-primary').addClass('btn-default');
            $item.addClass('btn-primary');
			allWells.hide();
			$target.show();
			$target.find('input:eq(0)').focus();
        }
    });
    allNextBtn.click(function(){
        var curStep = $(this).closest(".setup-content"),
            curStepBtn = curStep.attr("id"),
            nextStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().next().children("a"),
            curInputs = curStep.find("input[type='text'],input[type='url']"),
            isValid = true;

        $(".form-group").removeClass("has-error");
        for(var i=0; i<curInputs.length; i++){
            if (!curInputs[i].validity.valid){
                isValid = false;
                $(curInputs[i]).closest(".form-group").addClass("has-error");
            }
        }

        if (isValid)
            nextStepWizard.removeAttr('disabled').trigger('click');
    });
	allPrevBtn.click(function(){
        var curStep = $(this).closest(".setup-content"),
            curStepBtn = curStep.attr("id"),
            prevStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().prev().children("a"),
            curInputs = curStep.find("input[type='text'],input[type='url']"),
            isValid = true;
        $(".form-group").removeClass("has-error");
        for(var i=0; i<curInputs.length; i++){
            if (!curInputs[i].validity.valid){
                isValid = false;
                $(curInputs[i]).closest(".form-group").addClass("has-error");
            }
        }

        if (isValid)
            prevStepWizard.removeAttr('disabled').trigger('click');
    });
    $('div.setup-panel div a.btn-primary').trigger('click');
	$('#add-documents').click(function(){
		$('#uploadDocuments').toggle();
	});
});

/**
 *Funkcja do obsługi API
 */
 global.api= function api(url, body, handleData) {
	body = JSON.stringify(body);

	if (typeof body == "undefined" || body == "") {
		body = "{}";
	}

	$.ajax(url, {
		crossDomain: true,
		contentType: 'application/json',
		dataType: "json",
		type: "POST",
		data: body,
		xhrFields: {
			withCredentials: true
		},
		beforeSend: function (xhr) {
			xhr.setRequestHeader("Accept", "application/json");
			xhr.setRequestHeader("Content-Type", "application/json");
		},
		success: function (data) {
			if (data.status === true && data.action === 'refresh')
			{
				location.reload();
			}

			if (data.status === false && typeof data.message != 'undefined')
			{
				showAlert(data.message, 'danger');
			}

			if(data.status === true && typeof data.message != 'undefined'){
				showAlert(data.message, 'success');
			}

			handleData(data);
		},
		error: function (error) {

			if (typeof error.responseJSON.message !== 'undefined')
			{
				showAlert(error.responseJSON.message, 'danger');
			}

		}
	})
}

