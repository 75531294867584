/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

global.$ = global.jQuery = require('jquery');

// npm dependencies
import 'jquery-ui-bundle'
import 'bootstrap'
import 'bootstrap-table'
import 'select2'
import 'lightbox2/dist/js/lightbox-plus-jquery'


// resources dependecies
import './custom'
import './vendors/vendors'
import './mdb/mdb.min'
import './date-time-picker'
import './helpers/_inputmask'
import './helpers/_hideShowPassword'

/**
 * Datepicker z biblioteki jQuery UI z bootsrapową nakładką na wygląd.
 *
 * Najlepszy jaki znalzłem bo z innymi zawsze są jakieś problemy, lub bazują a nim tak naparwde
 */
$(function () {
    // From: https://codepen.io/dzentbolas/pen/QjXQVw
    $.datepicker._updateDatepicker_original = $.datepicker._updateDatepicker;
    $.datepicker._updateDatepicker = function (inst) {
        $.datepicker._updateDatepicker_original(inst);
        var afterShow = this._get(inst, 'afterShow');
        if (afterShow)
            afterShow.apply((inst.input ? inst.input[0] : null)); // trigger custom callback
    }

    // Reuseable..
    $.datepicker.setDefaults({
        afterShow: function () {
            $('a.ui-datepicker-prev').html('<i class="fas fa-arrow-left"></i>');
            $('a.ui-datepicker-next').html('<i class="fas fa-arrow-right"></i>');
        }
    });
	

		
});
